import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Button, ButtonProps, Flex, useMediaQuery, useToken } from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { ButtonLabel, SideMenuItem } from 'composable/types';
import routes from 'helpers/constants/routes';
import { Reference, getReferenceTarget } from 'helpers/reference';
import { getServerAzureLogoutUrl, userLogout } from 'hooks/global/use_privateUserGlobal/utils';
import { LOGOUT_CALLERS } from 'hooks/global/use_privateUserGlobal/constants';

type ButtonLinksProps = {
  links?: SideMenuItem[];
};

const BUTTON_LABELS = [ButtonLabel.LOG_IN_TO_ORDER?.toLowerCase(), ButtonLabel.LOG_OUT?.toLowerCase()];

export const ButtonLinks = ({ links }: ButtonLinksProps) => {
  const [isMobile] = useMediaQuery(`(max-width: (max-width: 1026px)`);
  const neutral700 = useToken('colors', 'neutral.700');
  const router = useRouter();
  let originUrl = router.asPath;
  const { azureRedirectUrl = '' } = useGlobal().useUserGlobal;
  const isErrorPage = router.asPath.includes(routes.PUBLIC_ERROR_PAGE);
  const loginError = (Array.isArray(router.query?.login) ? router.query?.login[0] : router.query?.login) || '';

  // Set origin for public PDP as regular PDP in case of user login for redirect.
  if (router.asPath.includes(routes.PUBLIC_PDP)) {
    originUrl = originUrl.replace(routes.PUBLIC_PDP, routes.PDP);
  }

  if (!links) return null;
  return (
    <Flex gap={5} w="100%" alignItems="center" flexDir={'column'} justifyContent={'flex-start'}>
      {links.map((link) => {
        const href = getReferenceTarget(link.link as Reference);
        const isExternal = link.link.openInNewWindow;
        const externalLinkProps = isExternal ? { target: '_blank', rel: 'noopener noreferrer' } : {};
        const buttonStyle: ButtonProps = {
          w: { base: 'fit-content', xl: '166px' },
        };

        if (link?.label?.toLowerCase() === ButtonLabel.JOIN_TODAY?.toLowerCase()) {
          return (
            <NextLink key={`public_left_side_menu_${link?.label}`} href={href} passHref>
              <Button
                as="a"
                size={isMobile ? 'ds-md' : 'ds-lg'}
                variant="ds-filled"
                {...buttonStyle}
                {...externalLinkProps}
              >
                {link?.label}
              </Button>
            </NextLink>
          );
        }

        if (BUTTON_LABELS.includes(link?.label?.toLowerCase())) {
          const isLogout = link?.label.toLowerCase() === ButtonLabel.LOG_OUT?.toLowerCase();
          const loginHref = isErrorPage ? getServerAzureLogoutUrl(loginError, originUrl) : azureRedirectUrl;
          const variant = isLogout ? 'ds-filled' : 'ds-ghost';
          return (
            <NextLink
              key={`public_left_side_menu_${link?.label}`}
              href={isLogout ? getServerAzureLogoutUrl('', originUrl) : loginHref}
              passHref
            >
              <Button
                bgColor={neutral700}
                size={isMobile ? 'ds-md' : 'ds-lg'}
                variant={variant}
                {...buttonStyle}
                {...externalLinkProps}
                {...(isLogout && {
                  textDecoration: 'underline',
                  onClick: () => userLogout(true, { caller: LOGOUT_CALLERS.HARD_LOGOUT_FROM_BUTTONS_LINKS }),
                })}
              >
                {link?.label}
              </Button>
            </NextLink>
          );
        }
      })}
    </Flex>
  );
};
